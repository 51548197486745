var formData = {
  rechargeMethod: [{
    id: "0",
    name: "Apple"
  }, {
    id: "1",
    name: "Alipay"
  }, {
    id: "2",
    name: "PayPal"
  }, {
    id: "3",
    name: "Google Pay"
  }, {
    id: "4",
    name: "Other"
  }]
};
export default formData;