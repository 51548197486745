var columns = [{
  title: '用户ID',
  dataIndex: 'uid',
  key: 'uid',
  width: '140px',
  scopedSlots: {
    customRender: 'uid'
  }
}, {
  title: '用户昵称',
  dataIndex: 'nickname',
  key: 'nickname',
  width: '100px',
  scopedSlots: {
    customRender: 'nickname'
  }
}, {
  title: '充值金额',
  dataIndex: 'rechargeamount',
  key: 'rechargeamount',
  width: '120px',
  scopedSlots: {
    customRender: 'rechargeamount'
  }
}, {
  title: '花费金额',
  dataIndex: 'useamount',
  key: 'useamount',
  width: '120px',
  scopedSlots: {
    customRender: 'useamount'
  }
}, {
  title: '充值方式',
  dataIndex: 'rechargetype',
  key: 'rechargetype',
  width: '100px',
  scopedSlots: {
    customRender: 'rechargetype'
  }
}, {
  title: '时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '160px',
  scopedSlots: {
    customRender: 'createtime'
  }
}];
export { columns };